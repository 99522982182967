body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.w-full{
  width: 100%;
}

.cardsec.themecard .card.shadowstyle {
  background-image: url("../src/images/irena/jigpattern.png"), url("../src/images/irena/jiggroup.png"),url("../src/images/irena/jigcolor.png");
  background-size: inherit;
  background-position: 100% 140%, 100% -26%, 100% 100%;
  background-repeat: no-repeat;
  background-color: #fff;
  box-shadow: 0px 0 14px 3px #e9e6e6;
  border: 0;
  min-height: 370px;
}
.newtheme  .headtitle{
  color: #373636;
}
.jigpiece {
  position: absolute;
  left: -61px;
  bottom: 0;
}
.jigpiece img {
  width: 148px;
}
section.newtheme{
  position: relative;
  z-index: 9;
}
section.newtheme:after,section.newtheme:before{
  content: "";
  position: absolute;
  filter: blur(50px);
  z-index: -1;
  height: 300px;
  width: 300px;
  top: 0;
  left: 0;
  margin: auto;
} 
section.newtheme:after {
  background: #6cdc644f;
  right: 0;
}
section.newtheme:before{
  background: #ff00003b;
  bottom: 0;
 
}
.dropdown .btn-success:hover,.dropdown .btn-success:focus, .show>.btn-success.dropdown-toggle{
  color: var(--textclr);
}
.show.dropdown a {
  color: var(--textclr);
}
.dropdown .btn-success img, .show.dropdown a img{
  filter: invert(0.7);
}
.dropdown .btn-success img
{
filter: brightness(0) invert(1);
}
p.bal_value img {
  filter: invert(0.5);
}

.stakingpage .accordion .accordion-header p{
  color: var(--textclr);
}
.accordion-button{
  background-image: linear-gradient(59deg, #49b6cd,#67d773,#e14637, #ed812f);;
}
.headerbg_black{
background: #fff;
border-radius: 4px !important;
}
span.navbar-toggler-icon {
  filter: invert(1);
}

div#header.fixedheader {
  background: #fff;
}













@media(min-width:991px){
  #header ul.navbar-nav{
    align-items: center;
  }
  #header ul.navbar-nav li {
    display: flex;
  }
}
@media(min-width:768px){
  .circlesec {
    position: relative;
    min-width: 668px;
    min-height: 617px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}