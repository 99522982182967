@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');	
:root{	
  --themeclr : #629b00;	
  --textclr:#3B3939	
}	
body{	
  font-family: 'Roboto', sans-serif;
}
a{
  color: #3DA9F1;
}
.MuiSlider-markActive {
  background-color: #f40808 !important;
}
.MuiSlider-mark {
  height: 4px !important;
  background-color: red !important;
}
.land 
{
  /* background-image: url("../src/images/bg.jpg"); */
  background-size: 100% ;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;

}
header img {
  height: 110px;
  margin-top: 6px;
}

.owl-nav button span {
  background: #629b00;
  width: 30px;
  display: inline-block;
  height: 30px;
  line-height: 1.8;
  border-radius: 50% !important;
  color: white;
}
.loader img.spin{
  max-width: 150px;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.leaf {
  position: absolute;
  height: 50px;
} 

@keyframes spin{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
.showloader {
  height: 100vh;
  position: fixed;
  background: #a1a1a1cc;
  width: 100%;
  z-index: 1031;
  margin: auto;
  display: none;
  top: 0px;
  left:0px;
}
.loader{
  display: flex;
align-items: center;
justify-content: center;
height: 100vh;
}

.yellowbtn[disabled] {
  cursor: not-allowed !important;
}
html{
  background-color: #fff;
}
.themeclr{
  color: var(--themeclr);
}
.upper {
  text-transform: uppercase;
}
.stepbg{
  width: 100%;
}
.owl-nav button span {
  background: #F3A12A;
  width: 40px;
  display: inline-block;
  height: 40px;
  line-height: 1.8;
  border-radius: 50% !important;
  font-size: 30px !important;
  color: #000000;
  display: inline-flex;
    align-items: center;
    justify-content: center;
}
.owl-nav button:hover span,.owl-nav button:hover{
  border-radius: 50% !important;
}
.steps3 .step1btn, .steps5 .step1btn{
  padding: 13px;
}
.steps3 .step1btn h3{
  color: #706e6f !important;
}
.step1btn {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  box-shadow: 0 0 16px gray;
  background-image: radial-gradient(#e7e7e7 20%, #c6c5c5 66%,var(--themeclr) 15%);
  outline: none;
  border: 1px solid var(--themeclr);
}
.step1btn p{
  margin-bottom: 0;
}
.step1btn h3 {
  color: #706e6f;
}
.steps {
  position: absolute;
  right: 88px;
  bottom: 0;
}
.steps1 {
  position: absolute;
  right: 12%;
  top: 26%;
}
.steps2 {
  position: absolute;
  left: 8%;
  bottom: 10%;
}
.steps3{
  position: absolute;
  bottom: 24%;
  right: 10%;
}
.steps4{
  position: absolute;
  left: 10%;
  bottom: 12%;
}
.steps5 {
  position: absolute;
  top: 25%;
  left: 15%;
}
.steps5 .step1btn{
  background-image: linear-gradient(#476e04, #6baa01);
  border-color: white;
  border-width: 5px;
  height: 130px;
  width: 130px;
  word-break: break-all;
}
.steps1 .step1btn, .steps2 .step1btn,
.steps3 .step1btn, .steps4 .step1btn {
  height: 140px;
  width: 140px;
  word-break: break-all;
}
.step1btn h3{
  font-size: 14px;
}
.laststep .steps1 .step1btn {
  display: block;
  width: 40px !important;
  height: 40px !important;
aspect-ratio: 1/1;
font-size: 12px;
line-height: 3;
}
.laststep .steps7 {
  position: absolute;
  right: 14%;
  top: 26%;
}
.step7btn{
  border-radius: 50%;
height: 126px;
width: 126px;
box-shadow: 0 0 16px gray;
background-image: radial-gradient(#e7e7e7 20%, #c6c5c5 66%,var(--themeclr) 15%);
outline: none;
border: 1px solid var(--themeclr);
}
.step7btn h3{
  font-size: 14px;
  color:#706e6f !important
}
.laststep .steps1 {
  position: absolute;
  right: 43%;
  top: 19%;
}
.laststep .steps1 .step1btn {
  height: 30px;
  width: 30px;
  background-image: linear-gradient(#b60d84, #701280);
  border-color: white;
  color: white;
  border-width: 2px;
}
.circlesec{
  position: relative;
}
.centertext {
    position: absolute;
}
.centertext {
  position: absolute;
  left: 0;
  right: 0;
  top: 14%;
  bottom: 0;
  height: min-content;
  margin: auto;
}
.centerinput .harvestbtn {
  width: 64px !important;
  background: #d4d6d5;
  color: white;
  border: none;
  color: gray;
  padding-left: 5px;
  margin-right: 5px !important;
  border-radius: 5px;
  height: 33px;
}
.centerinput button {
  width: 30px;
  border-radius: 5px;
  color: white;
  color: white;
  border: none;
}
.centerinput button.plus {
  background: #393939;
  margin-right: 5px;
  display: flex;
    align-items: center;
    justify-content: center;
}
.centerinput button.minus {
  background: #8c8a8b;
  display: flex;
    align-items: center;
    justify-content: center;
}
.bord-badge {
  border: 1px solid var(--themeclr);
  width: 100px;
  margin: auto;
  margin-bottom: 22px;
  border-radius: 5px;
}
.nav-item a {
  display: inline-block;
}

.nav-link {
  position: relative;
  overflow: hidden;
}




.nav-item.completed::after{
  background: #69ab01 !important;
}
.nav-link
{
  cursor: pointer;
}

.nav-link:hover,.nav-link:focus
{
  color:#000 !important;
}
.cnctbtn.btn {
  background: #629b00;
  color: white;
  position: relative;
  top: -16px;
}
.cnctbtn img {
  width: 14px;
  filter: invert(1);
}
.modal-content{
  /* height: 529px; */
  background: #fff;
  border: none;
  border-radius: 25px;
}
.modal-header {
  border: none;
}
.btn-close {
  opacity: 1 !important;
  /* filter: invert(1); */
}
.btn.cancel {
  background: #080808;
  color: white;
  box-shadow: 0 0 6px 4px #c8c8c8;
  margin-right: 10px;
}
.btn.confirm {
  background: #a7d30a;
  box-shadow: 0 0 6px 4px #c8c8c8
}
.modalcont input,.modalcont .input-group-text {
  border-radius: 0 10px 10px 0;
  background: #d8d6d7;
}
.modalcont .input-group-text {
  padding: 5px;
}
.maxbtn.btn {
  border-radius: 10px;
}
.modalcont .input-group button {
  background: #9f0d4e;
  color: white;
}
.modalcont input{
  border-radius: 10px 0 0 10px;
}
.circlemodal{
  position: relative;
}
.modalcont {
  position: absolute;
  top: 13%;
  z-index: 9;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 197px;
}
.tab-cont .centertext img{
  width: auto !important;
  margin: auto !important;
}
@media(max-width:767px){
  .border_right_md_wallet
  {
    border-bottom: 1px solid #ececec;
  }
  .steps1 .step1btn, .steps2 .step1btn, .steps3 .step1btn, .steps4 .step1btn {
    height: 120px;
    width: 120px;
  }
  .step1btn p {
    font-size: 12px;
  }
  .tab-cont .centertext .leafimg {
    width: 150px !important;
  }
  .steps .step1btn,.steps1 .step1btn, .steps2 .step1btn, .steps3 .step1btn, .steps4 .step1btn {
    /* height: 100px;
    width: 100px; */
  }
  .steps5 .step1btn {
    height: 80px;
    width: 80px;
  }
}
@media(min-width:768px){
  .sec_last .rectanglepuzzle1 {
    top: 1% !important;
}
.sec_last .rectanglepuzzle2 {
  top: 2% !important;
}
  .sec_last .roipuzzle {
    padding: 4% 0% 4% 0%;
}
 .border_right_md_wallet
 {
  border-right: 1px solid #ececec;
 }
  .minwidth-534{
    min-width: 668px;
  }
  .laststep .minwidth-534 {
    width: 668px;
    height: auto;
  }
}
@media(min-width:992px){
  .modalcont {
    left: -11%;
  }
  .home_sec_1 .sidearrow {
    bottom: 15% !important;
    right: 31% !important;
}
  .flexcolumn{
    flex-direction: column;
  }
}


@media(max-width:991px){


  .minwidth-534{
    margin: auto;
  }

  .nav-item:last-child {
    width: auto;
  }
  .nav-item::after {
    width: 100%;
    height: 3px;
    top: 20px;
    right: 0;
  }
  .nav-pills{
    justify-content: center;
  }

}
@media(max-width:600px){
  .modalbg {
    margin: auto;
    width: 100%;
  }
  .modalcont {
    left: -12%;
  }
}
@media(max-width:575px){
  .flex_drop_div
  {
    display: block !important;
  }
  
  .input_grp_nowrap
{
  flex-wrap: nowrap !important;
}


.input_grp_nowrap .dropdown-toggle
{
padding-left: 5px !important;
padding-right: 5px !important;
}

.input_grp_nowrap input
{
/* padding-left: 5px !important; */
padding-right: 5px !important;
}

.input_grp_nowrap .input-group-text
{
  padding-left: 0px !important;
padding-right: 5px !important;
}
.input_grp_nowrap .dropdown-toggle
{
  font-size: 11px !important;
}

  .laststep .steps7 {
    right: 6%;
    top: 26%;
  }
  .modalcont p.token {
    font-size: 10px;
    font-weight: 400;
  }
  h4.dephead{
    font-size: 14px;
  }
  h6.dephead{
    font-size: 12px;
  }
  .step1btn{
    box-shadow: none !important;
  }
  header img {
    height: 80px;
    margin-top: 8px;
  }
  .modalcont input{
    font-size: 12px;
  }
  .modalcont h3 {
      font-size: 18px;
  }
  .modalcont {
    width: 250px;
  }
  .cnctbtn.btn {
      padding: 2px 9px;
      font-size: 12px;
  }
  .head h2 {
    font-size: 18px !important;
    margin-bottom: 0;
  }
  .steps .step1btn, .steps1 .step1btn, .steps2 .step1btn, 
  .steps3 .step1btn, .steps4 .step1btn,.steps5 .step1btn {
    height: 120px;
    width: 120px;
  }

  .tab-cont .centertext .leafimg {
    width: 130px !important;
  }
  .step1btn p {
    font-size: 9px;
  }
  .centerinput .harvestbtn,.centerinput button {
    height: 23px;
  }
  .step1btn h3 {
    font-size: 12px;
    margin-bottom: 0;
  }
  .laststep .steps1 {
    right: 44%;
    top: 18%;
  }
  .steps5 {
    top: 17%;
    left: 15%;
  }
  .steps4 {
    left: 7%;
    bottom: 1%;
  }
  .steps3 {
    bottom: 12%;
    right: 3%;
  }
  .bord-badge {
      width: 60px;
      font-size: 12px;
      margin-bottom: 5px;
  }
  .centertext p {
    margin-bottom: 0;
  }
}
@media(max-width:475px){

  input{
    height: 34px;
font-size: 12px;
  }
  .modalcont .input-group button {
    height: 32px;
    line-height: 1;
    font-size: 12px;
  }
  .modalcont .input-group-text{
    padding: 0;
  }
  .btn.cancel , .btn.confirm{
    height: 30px;
    line-height: 1;
    font-size: 12px;
  }
  .centertext p {
    font-size: 12px;
  }
  .wall, .meta {
    width: 30px;
  }
  .arrow {
    display: none;
  }
  .steps {
    right: 43px;
    bottom: 0;
  }
   .steps2 .step1btn{
    height: 88px;
    width: 88px;
  }
  .steps .step1btn, .steps1 .step1btn, 
   .steps4 .step1btn {
    height: 80px;
    width: 80px;
  }

  .tab-cont .centertext .leafimg {
    width: 44px !important;
  }
}
@media(max-width:425px){
  .harvestbtn {
    font-size: 12px;
  }
  .steps5 {
    top: 15%;
    left: 6%;
  }
  .steps5 .step1btn ,.steps3 .step1btn {
    height: 100px;
    width: 100px;
  }
  .modalcont {
    width: 160px;
  }
  .hand {
    width: 25px;
    margin-top: 18px;
  }
  .modalcont h3 {
    font-size: 15px;
  }
}
@media(max-width:400px){
  .step7btn {
    height: 115px;
    width: 115px;
  }
  .laststep .steps7 {
    right: 0%;
    top: 18%;
  }
  .steps3 {
    bottom: 0%;
    right: 0%;
  }
 .steps3 .step1btn {
    height: 90px;
    width: 90px;
  }
}
@media(min-width:375px) and (max-width:575px){
  .steps4 {
    left: 20px;
    bottom: 11%;
  }
}

@media(max-width:375px){
  .steps4 {
    left: 0px;
    bottom: 16%;
  }
}

.cnctbtn.btn img{
  width: 14px !important;
  display: inline-block !important;
}
.cnctbtn.btn:hover,.btn.confirm:hover
{
  background: #080808;
    color: white;
}
.btn.cancel:hover
{
  background: #a7d30a;
  box-shadow: 0 0 6px 4px #c8c8c8;
  color: #212529;

}
.modalcont .input-group button:hover
{
  background: #a7d30a;
  color: #212529;
}
.step1btn:hover
{
  /* background-image: linear-gradient(55deg ,#69ab01 39% , #548603 84%) !important; */
  background-image: radial-gradient(#e7e7e7 20%, #c6c5c5 63%,#e5e6e5 15%);
    outline: none;
    /* border: 5px solid #d5d4d4; */
}
.steps3.steps_new1{
  position: absolute;
    left: 14% !important;
    right: unset !important;
    bottom: unset !important;

    top: 18%;
}
.steps4.steps_new1{
  position: absolute;
    right: 12% !important;
    left: unset !important;
    bottom: 0% !important;

}
.step1btn:hover .text-light

{
  color: #706e6f !important;
}

/* .modal-content
{
  align-items: center;
    justify-content: center;
    height: 100vh;
} */
.modal-header
{
  position: absolute;
    top: 5%;
    right: 0%;
    z-index: 1;
}
/* .modal-body

{
  display: flex;
    align-items: center;
    justify-content: center;
} */

@media only screen and (min-width:601px) and (max-width:991px)
{
  .modalcont
  {
    left:-12% !important;
  }
}

.btn,.btn-close
{
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
}

.close_btn{
  font-size: 39px;
font-weight: 200;
color: white;
}


/* USDI CSS */
.link_to_stake
{
  color:#629b01;
  margin-top: 25px;
}
.link_to_stake:hover
{
  color:#000;
}
.link_to_usdi
{
  color:#4da475;
  margin-top: 25px;
}
.link_to_usdi:hover
{
  color:#000;
}
.usdi_landing
{
  /* background-image: url("../src/images/bg_usdi.png"); */
  background-size: 100% ;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
}

.usdi_landing .nav-link.active,
.usdi_landing .nav-item.completed .nav-link,
.usdi_landing .nav-link:hover{
  border-radius: 50% !important;
  background-color: #4da475;
  background-image: linear-gradient(55deg ,#64d696 39% , #4da475 84%) !important;
}
.usdi_landing .steps5 .step1btn
{
  background-image: linear-gradient(#64d696, #4da475);
}
.usdi_landing .step7btn,
.usdi_landing .step1btn
{
  background-image: radial-gradient(#e7e7e7 20%, #c6c5c5 66%,#4da475 15%);
  border: 1px solid #4da475; 
}
.usdi_landing .themeclr
{
color:#4da475;
}
.usdi_landing .bord-badge
{
  border: 1px solid #4da475;
}
.usdi_landing .cnctbtn.btn
{
  background: #64d696;
}
.usdi_landing .nav-item.completed::after
{
  background: #64d696 !important;
}
.usdi_landing .step1btn:hover .text-light
{
  color:#fff !important;
}
.modal_usdi .btn.confirm,.modal_usdi .btn.cancel:hover
{
  background: #64d696;
}
.center_usdi
{
  max-width: 165px;
}
.modal_usdi .btn.confirm:hover
{
  background: #080808;
}
.modal_usdi .modalcont
{
  top:9%;
}
@media only screen and (min-width:992px)
{
  .sec_last .rectanglepuzzle1,.rectanglepuzzle2 {
    top: -8% !important;
}
  .sec_2 .puzzle,.graycircle {
    display: none;
}
  .modal_usdi .modalcont
  {
left: 0% !important;
  }
}
@media only screen and (max-width:600px)
{
  .modal_usdi .modalbg
  {
    min-width: 300px !important;
  }
  .modal_usdi .modalcont
  {
    left:-3% !important;
  }
}

@media only screen and (min-width:426px) and (max-width:575px)
{
  .modal_usdi .modalcont
  {
    top:20% !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 991px)
{
  .modal_usdi .modalcont {
    left: 0% !important;
}
}
.modal_usdi .modalcont .input-group button:hover
{
  background: #64d696;
}
.usdi_landing .centertext
{
  top:10%;
}

.usdi_landing  .owl-nav button span
{
  background: #64d696;
}
@media (max-width: 475px)
{
  .usdi_landing  .tab-cont .centertext .leafimg {
    width:50px !important;
    margin-top: 10px !important;
}
}

/* end USDI CSS */



/* NEW CSS */

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.btn_red
{
  background-color: #FF6570;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 20px 0px 20px 0px;
  padding: 8px 20px !important;
  box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  border: 1px solid #FF6570;
  font-size: 13px !important;
  font-weight: 600 !important;
  /* border-color: #F3A12A; */
  border-radius: 10px;
  margin: auto;
}
.btn_red:hover
{
background-color: transparent !important;
color:#FF6570 !important;
}
.big_image_wallet
{
  max-width: 150px;
  margin-bottom: 20px;
}
.wallet_connect_div_btn
{
  border-radius: 25px;
  max-width: max-content;
  padding: 5px 15px;
  cursor: pointer;
}
.wallet_connect_div_btn:hover
{
  /* background-color: #f3a12a; */
}

.wallet_connect_div_btn:hover span
{
color:#000000 !important;
}
.wallet_connect_div_btn:hover .img_sm_wallet_div
{
  background-color: #fff9;
  box-shadow: 0px 0px 8px #00000078;

}
.img_sm_wallet_div img
{
  max-width: 20px;
}
/* .headright_a:hover .img_sm_wallet_div{
  box-shadow: 0px 0px 8px #00000078;
} */
.img_sm_wallet_div
{
 
  background-color: #fff;
  border-radius: 10px;
  padding: 2px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

}

/* .spin_animation
{
  animation: rotation 2s infinite linear;
  transform-origin: 0;
} */
@media (min-width: 576px)
{
  .thememodal  .modal-dialog {
    max-width: 600px !important;
}
}

.wallet_text_center_white
{
  color:#000;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.wallet_text_heading
{
  color:#000;
  font-size: 13px;
  font-weight: 500;
}
.wallet_subtext_heading
{
  color:#000;
  font-size: 12px;
  font-weight: 400 !important;
}
.text_desc_a_span
{
  color:gray;
  font-size: 11px !important;
  font-weight: 300 !important;
  padding-right: 3px;
}
.close-btn
{
  border: 1px solid #F3A12A;
  width: 20px;
  height: 20px;
  color:#F3A12A;
  background-color: transparent !important;
  border-radius: 50%;
  font-size: 10px !important;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top:10px;

}
.a_with_span .headright_a
{
  font-size: 11px !important;
}
.modal_title_white {
  color: var(--textclr);
  font-size: 20px;
  font-weight: 600;
}
.wallet_connect_div span
{
  color:#000;
  font-size: 13px !important;
  margin-left: 10px;
}
.modal_subtitle_grey
{
  color:#9B9B9B;
  font-size: 12px !important;
}
.step_count
{
  display: none;
}
.step_count.active
{
  display: block;
}
.container_custom
{
  max-width: 93% !important;
  position: relative;
 
}
.logoheader
{
  max-width: 100px;
}
#navbarSupportedContent
{
  justify-content: flex-end;
}
#header .nav-link
{
color:black;
font-size: 14px;
font-weight: 500;
margin-right: 20px !important;
border-bottom: 1px solid transparent;
}
#header .nav-link:hover,.sectionmaxwidth .btn_yellow2:hover,.sec_reward .btn_yellow2:hover,.sec_last .btn_yellow2:hover,.sec_3 .btn_yellow2:hover,.sec_2 .btn_yellow2:hover,#header .nav-link:focus,#header .nav-link.active
{
  color: #F3A12A !important;
/* border-bottom: 1px solid #F3A12A; */

}
.sec_last .btn_yellow2{
  margin: auto;
}
#header .btn_yellow,.sec_2 .btn_yellow2,.sec_3 .btn_yellow2,.sec_last .btn_yellow2
{
 background: url("./images/btn-wo-sha4.jpg");
 background-repeat: no-repeat;
  cursor:pointer;
  border: none !important;
  color:#000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 12px 20px 12px 40px;	
  background-size:100% 100% ;
  border-radius: 8px;
}
.sectionmaxwidth .btn_yellow
{
  /* background-color: #F3A12A; */
 background: url("./images/btn2.png");
 background-repeat: no-repeat;
  cursor:pointer;
  border: none !important;
  color:#000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  /* /* border-radius: 20px 0px 20px 0px; */
  /* padding: 8px 20px !important;
  -webkit-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
    box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
    border:1px solid #F3A12A; */ 
}
.btn_yellow	,.sec_3 .btn_yellow3
{	
  background-color: #F3A12A;	
  border:1px solid  #F3A12A ;	
  border-radius: 10px;	
 box-shadow: 0px 10px 46px #00000078;	
 background-repeat: no-repeat;	
  cursor:pointer;	
  color:#fff !important;	
  font-size: 13px !important;	
  font-weight: 600 !important;	
  filter: drop-shadow(0 10px 46px #00000078);	
  padding: 8px;	
}
.btn_yellow:hover,.sec_2 .btn_yellow:hover,.sec_3 .btn_yellow3:hover
{
background-color: transparent !important;
color:#F3A12A !important;
}
#header .btn_yellow
{
  margin-right: 0px !important;
}
.landing_bg
{
 
background-image: url("./images/bg.png");
  background-size: 100%;
  background-position: bottom;
  background-color: white;
  background-repeat: no-repeat;
  /* background: radial-gradient(circle farthest-corner at center center, #121FCF 50%, #CF1512 100%); */
  /* min-height: 100vh; */
  overflow: hidden;
}
.text_yellow
{
  color: #373636;
}

.sec_2 .text_yellow
{
  color: #373636;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
}


/* .home_sec_1{
  background: url("./images/bg.png");
  background-repeat: no-repeat;
  background-color: white;
} */
.sectionmaxwidth{
  max-width: 30rem;
  margin: auto;
  background: url("./images/Path\ 5.png");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 10% 0%;
}
.home_sec_1 {
  position: relative;
}
.home_sec_1 .blugradient{
  position: absolute;
  background: transparent linear-gradient(254deg, #65a3cb8a 0%, rgb(202 230 248) 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  filter: blur(50px);
  max-width: 400px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  left: -20%;
  bottom: 100px;
  content: "";	
  top: 8%;
  transform: rotate(45deg);
}
.home_sec_1 .greengradient{
  position: absolute;
    background: transparent linear-gradient(254deg, #86f77dad 0%, rgb(206 243 203) 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    filter: blur(50px);
    max-width: 400px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    left: 35%;
    bottom: 40px;
    content: "";
    top: -40%;
    transform: rotate(45deg);
}
.home_sec_1 .orangegradient{
  position: absolute;
  background: transparent linear-gradient(254deg, rgb(219 165 84) 0%, rgb(253 237 213) 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  filter: blur(50px);
  max-width: 400px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  right: -20%;
  bottom: 100px;
  content: "";
  top: 20%;
  transform: rotate(45deg);
}
.sec_2 .btn-success:hover {
  color: black;
}
.sec_2 .btn-success:active, .show>.btn-success.dropdown-toggle {
  color: black;
}
.sec_2 .dropdown-menu {
  background-color: #ffff !important;
}

.sec_2 .redgradient{
  position: absolute;
  background: transparent linear-gradient(254deg, #ffbaba94 0%, rgb(247 231 231) 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  filter: blur(50px);
  max-width: 700px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  left: 37%;
  bottom: 100px;
  content: "";
  top: 10%;
  z-index: 1;
}
.sec_2 .blurgradient{
  position: absolute;
  background: transparent linear-gradient(254deg, #dfcece 0%, #e9e9e9 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  filter: blur(50px);
  max-width: 200px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  left: 0%;
  bottom: 100px;
  content: "";
  top: 50%;
  z-index: 1;
}
.duration_p{
  font-weight: 600;
}
.sec_3 .redgradient {
  position: absolute;
  background: transparent linear-gradient(254deg, #f39d9d9e 0%, rgb(246, 225, 225) 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  filter: blur(50px);
  max-width: 500px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  left: -20%;
  bottom: 100px;
  content: "";
  top: 25%;
  z-index: 1;
  transform: rotate(45deg);
}
.sec_3 .blurgradient{
  position: absolute;
  background: transparent linear-gradient(254deg, #dfcece 0%, #e9e9e9 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  filter: blur(50px);
  max-width: 200px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  right: 0%;
  bottom: 100px;
  content: "";
  top: 50%;
  z-index: 1;
}
.sec_3 .redgradient1{
  position: absolute;
    background: transparent linear-gradient(254deg, #f19898bd 0%, rgb(246, 225, 225) 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    filter: blur(50px);
    max-width: 400px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    right: -20%;
    bottom: 100px;
    content: "";
    top: 7%;
    z-index: 1;
    transform: rotate(45deg);
}
.sec_reward .blugradient{
  position: absolute;
    background: transparent linear-gradient(254deg, #90ccf5 0%, #e4f3fd 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    filter: blur(50px);
    max-width: 300px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    left: 35%;
    bottom: 100px;
    content: "";
    top: 10%;
    z-index: 1;
}
.sec_reward .orangegradient {
  position: absolute;
  background: transparent linear-gradient(254deg, #dfb577b3 0%, #fdedd5 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  filter: blur(50px);
  max-width: 500px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  right: -22%;
  bottom: 100px;
  content: "";
  top: 10%;
  z-index: 1;
  transform: rotate(45deg);
}
.sec_about .greengradient{
  position: absolute;
  background: transparent linear-gradient(254deg, #c4f5c1 0%, #e6f6e5 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  filter: blur(50px);
  max-width: 300px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  left: 40%;
  bottom: 100px;
  content: "";
  top: 8%;
  z-index: 1;
}
.sec_about .greengradient1{
  position: absolute;
    background: transparent linear-gradient(254deg, #c4f5c1d9 0%, #e6f6e5 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    filter: blur(50px);
    max-width: 450px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    right: -22%;
    bottom: 100px;
    content: "";
    top: 0%;
    z-index: 1;
    transform: rotate(45deg);
}
.sec_about .greengradient2{
  position: absolute;
  background: transparent linear-gradient(254deg, #c4f5c1 0%, #e6f6e5 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  filter: blur(50px);
  max-width: 400px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  left: -20%;
  bottom: 100px;
  content: "";
  top: 40%;
  z-index: 1;
  transform: rotate(45deg);
}
.sec_about .greengradient3{
  position: absolute;
    background: transparent linear-gradient(254deg, #7fbfe5b0 0%, #e6f2f9 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    filter: blur(50px);
    max-width: 400px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    right: -15%;
    bottom: 100px;
    content: "";
    top: 40%;
    z-index: 1;
    transform: rotate(45deg);
}
.sec_last .orangegradient{
  position: absolute;
  background: transparent linear-gradient(254deg, #dfb577b3 0%, #fdedd5 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    filter: blur(50px);
    max-width: 350px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    right: -20%;
    bottom: 100px;
    content: "";
    top: 20%;
    z-index: 1;
    transform: rotate(45deg);
}
.sec_last .greengradient{
  position: absolute;
    background: transparent linear-gradient(254deg, #d1f9d0 0%, #fafafa 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    filter: blur(50px);
    max-width: 350px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    left: 34%;
    bottom: 100px;
    content: "";
    top: 10%;
    z-index: 1;
}
.home_sec_1 .rectangle{
  max-width: 70px;
    position: absolute;
    bottom: 15%;
    left: 45%;
    animation: 7s ease-in-out infinite floating;
}
.home_sec_1 .sidepuzzle{
  max-width: 65px;
    position: absolute;
    top: 15%;
    left: 5%;
    animation: 7s ease-in-out infinite floating;
}
.home_sec_1 .sidepuzzle1{
  max-width: 65px;
    position: absolute;
    top: 25%;
    right: 9%;
    animation: 7s ease-in-out infinite floating;
}
.home_sec_1 .sidepuzzle2{
  max-width: 65px;
    position: absolute;
    bottom: 25%;
    right: 20%;
    animation: 7s ease-in-out infinite floating;
}
.home_sec_1 .sidearrow{
  max-width: 180px;
    position: absolute; 
    bottom: 19% !important;
    right: 40% !important;

}
.home_sec_1 .sidepuzzle3{
  max-width: 60px;
    position: absolute;
    bottom: 12%;
    left: 30%;
    animation: 7s ease-in-out infinite floating;
}
.home_sec_1 .rectangle1{
  max-width: 75px;
    position: absolute;
    bottom: 30%;
    /* left: 24%; */
    left: 5%;
    animation: 7s ease-in-out infinite floating;
}

.sec_2 .sidepuzzle{
  position: absolute;
  max-width: 50px;
  left: 50%;
  animation: 7s ease-in-out infinite floating;
  top: -8%;
}
.sec_2 .sidepuzzle3{
  max-width: 60px;
  position: absolute;
  bottom: 15%;
  right: 10%;
  animation: 7s ease-in-out infinite floating;
}

.sec_3 .sidepuzzle{
  position: absolute;
  max-width: 55px;
  left: 4%;
  animation: 7s ease-in-out infinite floating;
  /* top: 15%; */
  top: -9%;
}
.sec_3 .sidepuzzle3{
  position: absolute;
  max-width: 55px;
  left: 40%;
  animation: 7s ease-in-out infinite floating;
  bottom: 15%;
}

.sectionmaxwidth .banner_txt_yel
{
  font-size: 140px !important;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px !important;
  letter-spacing: 12px;
}
.sectionmaxwidth .banner_txt_desc
{
  font-size: 16px;
    color: #373636;
    font-weight: 600 !important;
    line-height: 1.6;
}
.sectionmaxwidth .content1{
  max-width: 65%;
}
.sectionmaxwidth .btn_yellow2{
  background: url("./images/btn-wo-sha5.jpg");
 background-repeat: no-repeat;
  cursor:pointer;
  border: none !important;
  color:#000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 10px 36px 10px 36px;
  transition: 0.5s;
}
/* .sectionmaxwidth .btn_yellow2:hover {
  border-radius: 10px;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  transition: 0.5s;
} */
.logosec2{
  max-width: 80px;
    margin-bottom: 15px;
}
.sec_2{
  background-image: url("./images/bg.png");
  background-size: 100%;
  background-position: bottom;
  background-color: white;
  background-repeat: no-repeat;
  padding: 6% 0%;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.sec_2 .whitecircle{
  /* position: absolute;
    max-width: 50px;
    left: -2%;
    top: 40%; */
    position: absolute;
    max-width: 50px;
    left: 26px;
    top: 50%;
}
.sec_2 .puzzle{
  max-width: 160px;
  position: absolute;
  left: 42%;
  top: 57%;
}
.sec_2 .puzzle1{
  position: absolute;
    max-width: 100px;
    left: 36%;
    bottom: -17%;
}
.sec_3 .puzzle3{
  position: absolute;
    max-width: 126px;
    right: -55px;
    bottom: 15%;
}
.sec_2 .pads{
  padding: 0px 55px;
}
.sec_3 .pads{
  padding: 0px 55px;
}
.sec_2 .graycircle{
  max-width: 65px;
  left: 43%;
  position: absolute;
  bottom: 20%;
}
.sec_3 .graycircle{
  position: absolute;
  right: 11px;
  bottom: 20%;
  max-width: 60px;
}
.sec_2 .daysborder{
  background: #f7f7f7;
    padding: 10px 20px;
    border-radius: 12px;
}
.sec_2 .rectanglepuzzle{
  position: absolute;
    right: 0px;
    max-width: 75px;
    bottom: 60%;
    animation: 7s ease-in-out infinite floating;
}
.sec_2 .rectanglepuzzle3{
  max-width: 75px;
    position: absolute;
    bottom: -7%;
}
.sec_2 .blurpuzzle{
  left: 40%;
  max-width: 160px;
  position: absolute;
  bottom: -10%;
  z-index: 0;
}
.sec_3 .blurpuzzle{
  position: absolute;
    max-width: 130px;
    right: 40%;
    bottom: -10%;
}
.sec_2 .roipuzzle{
  background: url("./images/Path\ 5.png");
  background-repeat: no-repeat;
  background-size: 80%;
  padding: 8% 0% 8% 0%;
  background-position: center;
}
.sec_3 .roipuzzle{
  background: url("./images/Path\ 5.png");
  background-repeat: no-repeat;
  background-size: 65%;
  padding: 8% 0% 8% 0%;
  background-position: center;
}
.sec_reward .roipuzzle{
  background: url("./images/Path\ 5.png");
  background-repeat: no-repeat;
  background-size: 25%;
  padding: 7% 0% 7% 0%;
  background-position: center;
}
.sec_about .roipuzzle{
  background: url("./images/Path\ 5.png");
  background-repeat: no-repeat;
  background-size: 20%;
  padding: 6% 0% 6% 0%;
  background-position: top;
  padding-top: 50px !important;
}
.sec_last .roipuzzle{
  background: url("./images/Path\ 5.png");
  background-repeat: no-repeat;
  background-size: 35%;
  padding: 8% 0% 8% 0%;
  background-position: center;
}
.sec_2 .banner_txt_desc {
  font-size: 13px;
  color: #373636;
  font-weight: 400 !important;
  line-height: 2;
}
.sec_2 .banner_txt_p{
  font-size: 16px;
  color: #373636;
  font-weight: 600 !important;
  line-height: 1.8;
}
.sec_2 .btn_yellow {
  background-color: #F3A12A;
  cursor: pointer;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-color: #F3A12A;
  border-radius: 10px;
  margin: auto;
}
/* .sec_2 .btn_yellow2 {
  background: url("./images/btn-wo-sha.png");
  background-repeat: no-repeat;
  cursor: pointer;
  border: none !important;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 20px 30px 20px 50px;
  background-size: 100% 100%;
} */
/* .sec_2 .btn_yellow:hover {
  background-color: #F3A12A !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-color: #F3A12A;
  border-radius: 10px;
  margin: auto;
} */


.sec_3{
  background-image: url("./images/bg.png");
  background-size: 100%;
  background-position: bottom;
  background-color: white;
  background-repeat: no-repeat;
  padding: 6% 0%;
  position: relative;
  overflow: hidden;
}
.sec_3 .rectanglepuzzle1{
    position: absolute;
    max-width: 80px;
    left: 42%;
    top: 20%;
    animation: 7s ease-in-out infinite floating;
}
.sec_3 .rectanglepuzzle3{
  max-width: 80px;
    position: absolute;
    right: 35%;
    bottom: -6%;
}
.sec_3 .puzzle1{
  position: absolute;
    right: 22%;
    max-width: 100px;
    top: -10%;
}
.sec_3 .puzzle2{
  position: absolute;
    right: 43%;
    max-width: 100px;
    top: 60%;
}
.sec_3 .text_yellow
{
  color: #373636;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
}
.sec_3 .banner_txt_desc {
  font-size: 13px;
  color: #373636;
  font-weight: 400 !important;
  line-height: 2;
}
.sec_3 .banner_txt_p{
  font-size: 16px;
  color: #373636;
  font-weight: 600 !important;
  line-height: 1.8;
}
/* .sec_3 .btn_yellow2{
  background: url("./images/btn-wo-sha5.jpg");
 background-repeat: no-repeat;
  cursor:pointer;
  border: none !important;
  color:#000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 12px 35px 12px 40px;
} */
.sec_3 .card_shadow
{
  /* background: url("./images/dash-wo -sha.png"); */
  /* background: url("./images/dash11.png"); */
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 14px;
}
.sec_3 .duration{
  justify-content: space-between;
}
.sec_3 .progress_card{
  background-color: white;
  box-shadow: 0 3px 12px #4caf5052;
    border-radius: 12px;
}
.sec_3 .progress_title{
font-weight: 600;
}
.sec_3 .progress_title1{
  font-weight: 600;
  font-size: 13px;
}
.sec_3 .nav-link {
  display: unset !important;
}
/* .sec_3 .btn_yellow3 {
  background-color: #F3A12A;
  cursor: pointer;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-color: #F3A12A;
  border-radius: 10px;
  margin: auto;
} */

.sec_reward {
  background-image: url("./images/bg.png");
  background-size: 100%;
  background-position: bottom;
  background-color: white;
  background-repeat: no-repeat;
  padding: 6% 0%;
  position: relative;
  overflow: hidden;
  background-position: top;
}
.sec_reward .rectanglepuzzle1{
  position: absolute;
  max-width: 75px;
  left: 25%;
  top: 22%;
  animation: 7s ease-in-out infinite floating;
}
.sec_reward .rectanglepuzzle2{
  position: absolute;
    max-width: 75px;
    right: 25%;
    top: 22%;
    animation: 7s ease-in-out infinite floating;
}
.sec_reward .text_yellow
{
  color: #373636;
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
}
.sec_reward .banner_txt_p{
  font-size: 16px;
  color: #373636;
  font-weight: 600 !important;
  line-height: 1.8;
  text-align: center;
}
.sec_reward  .btn_yellow2 {
  background: url("./images/btn-wo-sha4.jpg");
  background-repeat: no-repeat;
  cursor: pointer;
  border: none !important;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 15px 30px 15px 40px;
  margin: auto;
}
.sec_reward .reward1{
  max-width: 80px !important;
}
.sec_reward .card{
  /* background: transparent linear-gradient(180deg, #FFFFFF 0%, #D9D9D9 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
  /* background-color: #f4f3f3; */
  border: 1px solid rgb(0 0 0 / 4%);
  border-bottom: none;
  background: linear-gradient(0deg, white, rgb(242 240 240) 80%) no-repeat;
}
.sec_reward .shadowcard{
  margin-top: 22%;
}
.sec_reward .card::after{
   /* content: "";
   position: absolute;
    z-index: -1;
    height: 4rem;
    width: calc(100% - 0rem);
    left: 0rem;
    clip-path: polygon(10% 0, 90% 0, 100% 100%, 0% 100%);
    right: 0.25rem;
    bottom: calc(100% - 0rem);
    background: linear-gradient(#e7e7e7 -5.71%, #e7e7e70f 100%);
    z-index: 1;  */
    content: "";
    position: absolute;
    z-index: -1;
    height: 4rem;
    width: calc(100% - 0rem);
    left: 0rem;
    clip-path: polygon(10% 0, 90% 0, 100% 100%, 0% 100%);
    right: 0.25rem;
    bottom: calc(100% - 0rem);
    background: linear-gradient(#fdfdfd -29.71%, #6e6e6e0f 100%);
    z-index: 1;
}
.sec_reward .rewardpuzzle{
    margin-top: -75px;
    max-height: 132px;
    object-fit: cover;
    object-position: top;
    max-width: 50px !important;
}
.sec_reward .puzzleborder{
  padding-bottom: 14px;
  border-bottom: 2px solid #e0dfdf;
}
.sec_reward .diamond{
  max-width: 22px !important;
  margin-top: -4px;
}
.sec_reward h5{
  margin-bottom: 0px !important;
}
.sec_reward .days{
  margin-bottom: 0px !important;
  font-size: 14px !important;
  font-weight: 500;
    margin-top: 5px;
}
.sec_reward .rewardcard1{
  margin-top: 6%;
}
.sec_reward .cardlast{
  background-color: white !important;
  border: 1px solid rgb(0 0 0 / 0%);
  border-radius: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  max-width: 60% !important;
  margin: auto;
  margin-top: 3%;
  padding-top: 6px;
  padding-bottom: 6px;
}
.sec_reward .cardlast .card-body{
  padding: 0.5rem 0.5rem !important;
  text-align: center
}
.sec_reward .cardlast p{
 margin-bottom: 0px !important;
 font-size: 12px;
 font-weight: 600;
}

.sec_about {
  background-image: url("./images/bg.png");
  background-size: 100%;
  background-position: bottom;
  background-color: white;
  background-repeat: no-repeat;
  padding: 4% 0% 3% 0%; 
  position: relative;
  background-position: center;
  overflow: hidden;
}
.sec_about .rectanglepuzzle1{
  position: absolute;
  max-width: 75px;
  left: 25%;
  top: 20%;
  animation: 7s ease-in-out infinite floating;
}
.sec_about .rectanglepuzzle2{
  position: absolute;
    max-width: 75px;
    right: 25%;
    top: 20%;
    animation: 7s ease-in-out infinite floating;
}
.sec_about .i1{
  position: relative;
  text-align: center;
}
.sec_about .aboutimg{
  max-width: 60%;
    margin: auto;
}
.sec_about .m2e{
  position: absolute;
  top: 15%;
}
.sec_about .m2e::before{
  position: absolute;
  top: 30%;
  border: 1px solid #9e9e9e70;
  content: "";
  width: 320px;
  left: 100%;
}
.sec_about .m2e1{
  position: absolute;
  right: 0;
  top: 15%;
}
.sec_about .m2e1::before{
  position: absolute;
  top: 30%; 
  border: 1px solid #9e9e9e70;
  content: "";
  width: 320px;
  right: 100%;
}
.sec_about .m2e2{
  position: absolute;
  left: 0; 
  bottom: 15%;
}
.sec_about .m2e2::before{
  position: absolute;
  bottom: 30%;
  border: 1px solid #9e9e9e70;
  content: "";
  width: 320px;
  left: 100%;
}
.sec_about .m2e3{
  position: absolute;
  right: 0; 
  bottom: 25%;
}
.sec_about .m2e3::before{
  position: absolute;
  bottom: 30%; 
  border: 1px solid #9e9e9e70;
  content: "";
  width: 260px;
  right: 100%;
}

.sec_about .irena{
  margin-bottom: 5px !important;
  text-align: left;
  font-weight: 600;
  color:#706d6c !important;
}

.sec_about .text_yellow
{
  color: #373636;
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
}
.sec_about .banner_txt_p{
  font-size: 16px;
  color: #373636;
  font-weight: 600 !important;
  line-height: 1.8;
  text-align: center;
}



.sec_last {
  background-image: url("./images/bg.png");
  background-size: 100%;
  background-position: bottom;
  background-color: white;
  background-repeat: no-repeat;
  padding: 4% 0%;
  position: relative;
  overflow: hidden;
}
.sec_last .rectanglepuzzle1{
  position: absolute;
  max-width: 75px;
  left: 19%;
  /* top: 20%; */
  top: 15%;
  animation: 7s ease-in-out infinite floating;
}
.sec_last .rectanglepuzzle2{
  position: absolute;
    max-width: 65px;
    right: 19%;
    top: 20%;
    animation: 7s ease-in-out infinite floating;
}
/* .sec_last .btn_yellow2{
  background: url("./images/btn-wo-sha4.jpg");
    background-repeat: no-repeat;
    cursor: pointer;
    border: none !important;
    color: #000000 !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 15px 20px 15px 40px;
    margin: auto;
   
} */
.sectionlast{
  max-width: 40rem;
  margin: auto;
}
.sec_last .text_yellow
{
  color: #373636;
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
}
.sec_last .banner_txt_p{
  font-size: 16px;
  color: #373636;
  font-weight: 600 !important;
  line-height: 1.8;
  text-align: center;
}
.sectionmaxwidth .banner_txt_p
{
  font-size: 14px;
  color:#373636;
  font-weight:500 !important;
  line-height: 2;
}

@media only screen and (max-width: 1200px)  {

  .sec_3 .puzzle3{
    position: absolute;
      max-width: 126px;
      right: -45px;
      bottom: 14%;
  }
  .sec_about .m2e::before, .m2e1::before, .m2e2::before, .m2e3::before {
    display: none;
  }
  /* .sec_3 .redgradient1 {
    right: 10%;
} */
/* .sec_about .greengradient1 {
  right: 10%;
}
.sec_about .greengradient3 {
  right: 10%;
}
.sec_last .orangegradient {
  right: 10%;
} */
/* .sec_reward .orangegradient {
  right: 10%;
} */
/* .sec_3 .blurgradient {
  right: 10%;
}
.sec_2 .redgradient {
  left: 30%;
} */
/* .home_sec_1 .orangegradient {
  right: 5%;
} */
}

@media only screen and (max-width: 991px) {
  .home1 .redgradient, .redgradient1, .blurgradient, .blugradient, .greengradient, .greengradient1, .greengradient2, .greengradient3, .orangegradient {
    display: none;
  }
  #header .btn_yellow {
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 12px 20px 12px 20px;
    background-size: 100% 100%;
}
.home_sec_1 .sidearrow {
  display: none;
}


}

@media only screen and (max-width: 840px) {
  .home1 .redgradient, .redgradient1, .blurgradient, .blugradient, .greengradient, .greengradient1, .greengradient2, .greengradient3, .orangegradient{
    display: none;
  }
  .sec_2 .pads{
    padding: 0px 0px;
  }
  .sec_3 .pads{
    padding: 0px 0px;
  }
  .sec_3 .puzzle3 {
    position: absolute;
    max-width: 85px;
    right: -40px;
    bottom: 13%;
}
.sec_3 .graycircle {
 display: none;
}
.sec_reward .shadowcard {
  margin-top: 30%;
}
}
@media only screen and (max-width: 767px) {
  .sidepuzzle, .sidepuzzle1, .sidepuzzle2, .sidepuzzle3, .puzzle, .puzzle3, .whitecircle, .graycircle {
    display: none;
  }
 .home1 .rectangle, .rectangle1{
    display: none;
  } 
  .home1 .rectanglepuzzle, .rectanglepuzzle1, .rectanglepuzzle2, .rectanglepuzzle3{
    display: none;
  }
  .home_sec_1 .sidearrow {
       display: none;
  }
  .sec_about .m2e {
    top: 4%;
}
.sec_about .m2e1 {
  top: 5%;
}
.sec_about .m2e2 {
  bottom: 7%;
}
.sec_about .m2e3 {
  right: -10px;
}

.sec_about h5
{
  color: #6f6b6a !important;
  font-size: 25px !important;

}

  .sec_about .m2e,.m2e1,.m2e2,.m2e3 .irena {
   font-size: 9px;
  }
  .sec_about .m2e h5{
    font-size: 11px !important;
   } 
   .sec_about .m2e1 h5{
    font-size: 11px !important;
   }
   .sec_about .m2e2 h5{
    font-size: 11px !important;
   }
   .sec_about .m2e3 h5{
    font-size: 11px !important;
   }
   .sec_reward .cardlast {
    max-width: 90% !important;
}
}




@media only screen and (max-width: 500px) {
  .sectionmaxwidth  .banner_txt_yel
  {
    font-size: 70px !important;
  }

 
  #header .btn_yellow {
    background: url("./images/btn-wo-sha4.jpg");
    background-repeat: no-repeat;
    cursor: pointer;
    border: none !important;
    color: #000000 !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 12px 20px 12px 20px;
    background-size: 100% 100%;
}
}




.coin_row img
{
  max-width: 50px !important;
}
.sec_2 .card_shadow
{
  /* background-color: #060606; */
   /* box-shadow: 0px 1px 12px #3DA9F196;
  border-radius: 20px; */
  /* background: url("./images/dash11.png"); */
  background-color: white !important;
  background-repeat: no-repeat;
  background-size: 100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 14px;
}
.text_sliver
{
  color: #3B3939;
  font-size: 12px;
  font-weight: 600;
}
.progress_text_div p
{
  color: #3B3939;
  font-size: 14px;
  font-weight: 600;
}
.single-thumb .range-slider__thumb[data-lower] {
  width: 0 !important;
  background: transparent !important;
  border:none !important;
  left:0px !important;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}
.range-slider
{
  background-color: #131313 !important;
  height: 4px !important;
}
.range-slider .range-slider__thumb
{
  width: 15px !important;
    height: 15px !important;  
    background: #F3A12A !important;
    border: 2px solid #1c8dcc !important;
}
.dropdwn_trna button
{
background-color: transparent !important;
border:none !important;
color: #3B3939;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 0px !important;
}
.dropdown-menu	
{	
  background-color: #fff !important;	
  color: var(--textclr);	
    font-size: 12px;	
}
.dropdown-menu .dropdown-item
{
  color: #ECECEC;
  font-size: 12px;
}
.dropdown-menu .dropdown-item:hover,	
.dropdown-menu .dropdown-item:focus	
{	
  background-color: #f5f5f5 !important;	
}
.text_silver_desc
{
  color: #ECECEC;
  font-size: 12px;
  font-weight:300 !important;
}
.card_carusel
{
  background-color: #1A1A1A;
  box-shadow: 0px 4px 17px #3DA9F140;
  border-radius: 2px;
}
 .owl-item.active.center .card_carusel 
{
  box-shadow: 0px 0px 13px #E8D257A6;
}
.card_heading_white
{
  color:#fff;
  text-transform: uppercase;
  font-weight: 300 !important;
  text-align: center;
  margin-bottom: 0px !important;
}
.text_price
{
  color:#F3A12A;
  text-align: center;
  margin-bottom: 0px !important;
}
.text_price_blue
{
  color:#3DA9F1;
  text-align: center;
  margin-bottom: 0px !important;
}
.text_price_blue span
{
  font-size: 30px;
}
.text_price span
{
  font-size: 30px;
}
.days_grey_price
{
  color:#fff;
  font-size: 12px;
  text-align: center;
  font-weight: 300 !important;

}
.card_ul_car
{
  list-style-type: none !important;
}
.card_ul_car li
{
  
  font-size: 12px;
  padding: 15px 10px;
}
.card_ul_car li:nth-child(odd)
{
  background-color: #83767629;
  color:#878B91 !important;
}
.card_ul_car li:nth-child(even)
{
  background-color: transparent;
  color:#878B91 !important;
}
.check_yellow
{
  color:#F3A12A;

}
.MuiSlider-rail
{
  background-color: #131313 !important;
  height: 4px !important;
}
.MuiSlider-root
{
  /* width: 15px !important;
    height: 15px !important;   */
    color: #F3A12A !important;
    /* border: 2px solid #1c8dcc !important;  */
}
.MuiSlider-thumb	
{	
  width: 15px !important;	
    height: 15px !important; 	
    background-image: linear-gradient(2deg, #49b6cd,#67d773,#e14637, #ed812f);	
    z-index: 9;	
}	
.MuiSlider-thumb:before {	
  content: "";	
  /* background: red; */	
  height: 50px;	
  width: 50px;	
  display: block;	
  aspect-ratio: 1/1;	
  border-radius: 50%;	
  z-index: 0;	
  position: absolute;	
}


.btn_yellow_outline
{
  background-color: transparent !important;
  color:#F3A12A !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 20px 0px 20px 0px;
  padding: 8px 20px !important;
  -webkit-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
    box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
    border:1px solid #F3A12A;
}
.btn_yellow_outline:hover
{
background-color: #F3A12A !important;
color:#000000 !important;
}
.carousel_home_theme .owl-stage-outer
{
  padding: 70px 20px;
  margin: -20px;
}
.card_carusel
{
  position: relative;
}
.star_img_carousel
{
  position: absolute;
  top:0px;
  right:20px;
  width: 35px !important;
  /* height: 11px !important; */
  /* z-index: 999; */
}
.owl-item.active.center .btn_yellow_outline 
{
  background-color: #F3A12A !important;
color:#000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 20px 0px 20px 0px;
  padding: 8px 20px !important;
  -webkit-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
    box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
    border:1px solid #F3A12A;
}
.owl-item.active.center .btn_yellow_outline:hover
{
  background-color: transparent !important;
  color:#F3A12A !important;
}
.owl-item.active.center 
{
  transform: scale(1.2);
  z-index: 2;
}
.carousel_home_theme .owl-prev
{
  position: absolute;
    left: -100px;
    top: 50%;
}
.carousel_home_theme .owl-next
{
  position: absolute;
    right: -100px;
    top: 50%;
}
.home_sec_4
{
  position: relative;
  background: transparent radial-gradient(closest-side at 43% 43%, #011B33 0%, #000000 100%);
  /* background-position: left -100px top 20px; */

}
.text_footer
{
  color: #727171;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}
.social_icon_div a:hover .fa{
color: #F3A12A !important;
}
.social_icon_div .fa
{
  color:#000;
  margin-left: 10px;
}
.social_icon_div a:hover .fa
{
  color:#fff;
}
#footer
{
  /* border-top:1px solid #E8D257; */
  border-top: 1px solid #9e9e9e73;
}
/* #footer .greengradient{
  position: absolute;
    background: transparent linear-gradient(254deg, #d1f9d0 0%, #fafafa 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    filter: blur(50px);
    max-width: 350px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    left: 34%;
    bottom: 100px;
    content: "";
    top: 10%;
    z-index: 1;
} */
@media only screen and (max-width:575px)
{
  .owl-item.active.center
  {
    transform: scale(1) !important;
  }
  .carousel_home_theme .owl-prev
  {
    left:-20px !important;
  }
  .carousel_home_theme .owl-next
  {
    right:-20px !important;
  }
  /* .btn_yellow,.btn_yellow_outline
  {
    padding-left: 15px !important;
    padding-right: 15px !important;
  } */
  .banner_txt_yel
  {
    font-size: 30px !important;
  }
  .carousel_home_theme .owl-stage-outer
  {
    padding: 35px 20px !important;
  }
  .row_rev
  {
    flex-direction: column-reverse;
  }
  #footer .d-flex
  {
    display: block !important;
  }
  .social_icon_div a:first-child .fa
  {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width:576px) and (max-width:767px)
{
  .row_rev
  {
    flex-direction: column-reverse;
  }
  .carousel_home_theme .owl-stage-outer
  {
    padding: 35px 20px !important;
  }
  .owl-item.active.center
  {
    transform: scale(1) !important;
  }
  .carousel_home_theme .owl-prev
  {
    left:-20px !important;
  }
  .carousel_home_theme .owl-next
  {
    right:-20px !important;
  }
}
@media only screen and (min-width:768px) and (max-width:991px)
{
  .sec_2 .puzzle,.sec_2 .whitecircle
  {
    display: none;
  }
  .flex_drop_div
  {
    display: block !important;
  }
  .flex_drop_div p
  {
    padding-right: 2px !important;
  }
  .banner_txt_yel
  {
    font-size: 30px !important;
  }
  /* .btn_yellow,.btn_yellow_outline
  {
    padding-left: 15px !important;
    padding-right: 15px !important;
  } */
  .coin_row .text_yellow
  {
    font-size: 14px !important;
  }
  .carousel_home_theme .owl-prev
  {
    left:-30px !important;
  }
  .carousel_home_theme .owl-next
  {
    right:-30px !important;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px)
{
  .carousel_home_theme .owl-prev
  {
    left:-70px !important;
  }
  .carousel_home_theme .owl-next
  {
    right:-70px !important;
  }
}
/* .home_sec_4::after
{
  content: "";
  position: absolute;
  width: 90%;
  height: 100px;
  background: transparent radial-gradient(closest-side at 19% 43%, #011B33 0%, #000000 100%) 0% 0% no-repeat padding-box;
} */
.humb_icon {
  width: 35px;
  height: 3px;
  background-color: #030005;
  margin: 5px 0;
  border-radius: 2px;
}
.first_hamburger, .second_hamburger, .third_hamburger {
  position: relative;
}
#burger, #burger .humb_icon {
  transition: 0.5s ease-in-out;
}
.first_hamburger::before, .second_hamburger::before, .third_hamburger::before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #fff;
  width: 35px;
  height: 3px;
}
.second_hamburger {
  margin-left: 10px !important;
}
#basic-navbar-nav
{
  /* justify-content: flex-end; */
  justify-content: space-between;
}
.navbar-toggler-icon
{
  background-image: url("../src/images/navbar_icon.png") !important;  
  /* background-image: url("../src/images/more.png") !important;  */
}

@media only screen and (max-width:991px)
{
  .shadowstyle 
  {
    padding-left:5px !important;
    padding-right: 5px !important;
  }
    .shadowstyle.px-3
  {
    padding-left:15px !important;
    padding-right: 5px !important;
  }
  .navbar-nav
  {
    /* background-color: #060606; */
    background-color: white;
    box-shadow: 0px 1px 12px #3DA9F196;
    padding: 10px 20px 25px;
    position: absolute;
    width: 100%;
    z-index: 9;
    top:100px;
    border-radius: 20px!important;
  }
}
#header
{
      position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    /* background: #000; */
    background: #ffffff52;
    border-bottom: 1px solid #00000024;
}
.home_page #header
{
  /* background: linear-gradient(216deg, black 50%, #020b13 63%); */
}
.pt-main-top
{
  padding-top: 80px;
}
.days
{
  position: relative;
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px !important;
}
.stakingpage .accordion .accordion-header p.days
{
  font-size: 17px !important;

}
.days span{
  font-size: 12px !important;
  /* margin-top: -10px !important; */
  position: absolute;
  top: -3px !important;
  right: -10px !important;
}
.font_acc
{
  font-size: 11px !important;
  font-weight: 600 !important;
}
.font_acc span:first-child
{
  font-weight: 700 !important;
}
.font_acc:last-child
{
  margin-bottom: 0px !important;
}

.accordion_row_height
{

  /* toggle lines */
  /* min-height:320px !important; */
  /* toggle lines */

  min-height:170px !important;

}
html,body	
{	
  scrollbar-color: #f3a12a #f1dec2;	
  scrollbar-width: thin;	
}	
*::-webkit-scrollbar {	
  width: 6px;	
  height: 5px;	
}	
*::-webkit-scrollbar-track {	
  /* box-shadow: inset 0 0 6px #187bb2; */	
  background-color: #f1dec2;	
}	
*::-webkit-scrollbar-thumb {	
  background-color: #f3a12a;	
}
.badge_pro
{
  background-color: #3DA9F1;
  color:#fff;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 2px;
    top: 2px;
    border-radius: 0px 10px 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.badge_pro .fa
{ 
  font-size: 12px !important;
}
.imgblk
{
  margin-right: 5px;
  filter: brightness(1) invert(1);
  max-width: 10px;
}
.imgwht
{
  margin-right: 5px;

}
.headright_a
{
  color: #3DA9F1;
    font-size: 13px;
    font-weight: 500;
}
.headright_a:hover
{
  color:#000;
}
.text-right
{
  text-align: right !important;
}
.white_text_sm
{
  color:#000;
  font-size: 13px !important;
  font-weight:500 !important;
}
.yellow_text_big
{
  color:#F3A12A;
  font-size: 16px !important;
}
.progrs_yelw.progress
{
  height: 8px;
  border-radius: 10px
}
.progrs_yelw.progress .progress-bar
{
  background-color:#F3A12A;

}
.row_progress_summary
{
  position: relative;
  border-left:1px solid #d9d9d9;
  padding-left: 10px;
}
.row_progress_summary .col_left_prog::before
{
  content: "";
  position: absolute;
  width:10px;
  height: 10px;
  background-color: #F3A12A;
  border-radius: 50%;
  left:-5px;
  top:0px;
}
.row_progress_summary p
{
  margin-top: -3px;
}
.row_without_circle_summary
{
  position: relative;
  border-left:1px solid #d9d9d9;
  padding-left: 10px;

}
.row_progress_summary.row_progress_summary_last
{
  border-left: none !important;
}
.custom-control-label span
{
  color: #000;
    font-size: 13px !important;
    font-weight: 300 !important;
}
.row_normal_summary
{
  padding-left: 10px;

}

.circle-loader {
  margin-bottom: 20px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-left-color: #1b9f62;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #1b9f62;
  transition: border 500ms ease-out;
}

/* .checkmark {
  display: none;
} */
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  transform-origin: left top;
  border-right: 5px solid #1b9f62;
  border-top: 5px solid #1b9f62;
  content: "";
  left: 1.25em;
  top: 3.5em;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}

.custom-checkbox
{
align-items: center;
}
@media only screen and (max-width:767px) {
  .sectionmaxwidth .content1 {
    max-width: 100%;
}
  .homeindex{
    padding-bottom: 30px;
  }
  .sec_2, .sec_3, .sec_about, .sec_last{
    padding: 2% 0%;
  }
  .sec_reward .roipuzzle {
    padding: 3% 0% 3% 0%;
}
  .sectionmaxwidth {
   background-image: unset !important;
}
  .sectionmaxwidth .banner_txt_yel{
    font-size: 45px !important;
  }
  .sec_last .text_yellow {
    font-size: 28px !important;
}
  .sectionmaxwidth,.sec_3,.sec_about,.sec_last,.sec_reward,
  .sec_2 .roipuzzle,.sec_3 .roipuzzle,.sec_last .roipuzzle{
    padding: 0;
    background-image: none;
  }
  .sec_2 .text_yellow,.sec_3 .text_yellow,.sec_reward .text_yellow,
  .sec_about .text_yellow{
    font-size: 40px;
  }
  .sectionmaxwidth{
    max-width: 100%;
  }
.dot_double_por
{
  display: none;
}
}

@media only screen and (min-width:1200px) and (max-width:1349px)
{
  .col-xl-4.col-xl-custom
  {
    width: 40% !important;
  }
}


/* END NEW CSS */



/*manoj css */
.innerpages .headsec{
  display: flex;
  justify-content: space-between;
}

.stakingpage .container_custom
{
  max-width: 93% !important;
}

.innerpages .headsec .headtitle{	
  letter-spacing: 1.75px;	
  font-weight: 600;	
  }	
  .innerpages .headsec .headright{	
   color: #3DA9F1;	
   font-size: 13px;	
   font-weight: 500;	
    }	
    .headright:hover	
    {	
      /* color:#fff !important; remove*/	
      font-weight: 500;	
    }	
      
  .card.shadowstyle{	
    background-color: #060606;	
    border-radius: 20px;	
    box-shadow: 0px 1px 12px #3DA9F196;	
    padding: 18px;	
  }	
  .card.shadowstyle .card-title{	
    color: var(--textclr);	
    font-size: 18px;	
    text-transform: uppercase;	
    font-weight: 600 !important;	
  }	
  .card-title-sm 	
  {	
    color: #242423;	
    font-size: 14px;	
    text-transform: uppercase;	
  }

.stakingpage .accordion{
  display: flex;
  gap: 10px;
  overflow: auto;
  
}
.stakingpage .accordion-item{
  min-width: 225px;
  max-width: 225px;
  background-color: unset !important;
  overflow: auto;

}
.stakingpage .accordion .accordion-button[aria-expanded="true"] .headerbg{
  /* background: transparent linear-gradient(228deg, #b8b047 28%, #6dae9b 100%) 0% 0% no-repeat padding-box !important; */
  padding: 5px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

    /* remove lines */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

    /* remove lines */

    /* add lines */

  /* border-bottom: 1px dashed #fff; */
  /* add lines */

}
.stakingpage .accordion-button:not(.collapsed){	
  color: transparent !important;	
  border: 0px solid #b3af4b;	
    border-radius: 5px;	
    /* border-top-left-radius: 15px;	
    border-top-right-radius:15px; */	
    /* remove lines */	
    /* border-bottom-left-radius: 15px;	
    border-bottom-right-radius:15px; */	
    /* remove lines */	
}
.stakingpage .accordion-collapse{
  margin-top: -3px;
}
.stakingpage .accordion .accordion-button[aria-expanded="true"] .headerbg_black{
  width: 100%;
  padding: 16px;
  text-align: center;
 

}
.stakingpage .accordion-button:focus{
  border:none !important;
}
.stakingpage .accordion .accordion-button[aria-expanded="false"] .headerbg{
  /* background: transparent linear-gradient(212deg, #F3A12A 0%, #3DA9F1 100%) 0% 0% no-repeat padding-box !important; */
  padding: 2px;
  width: 100%;
  border-radius: 10px;
}
.stakingpage .accordion .accordion-button[aria-expanded="false"] .headerbg_black{
 /* background-color:#060606; */
border-radius: 10px;
 width: 100%;
 padding: 20px;
 text-align: center;
}
.stakingpage .accordion .accordion-button{
 padding: 0px;
}
.stakingpage .accordion-button::after{
  display: none;
}
.stakingpage .accordion-header{	
  /* background: transparent; */	
  border: none !important;	
 	
}	
.stakingpage .accordion-item{	
  border: 0;	
}
.stakingpage .accordion-button[aria-expanded="false"] {
  color: #fff !important;
  /* background-color: #000; */
  border-radius: 5px;
  border: none;
}
.stakingpage .accordion-button{
  display: flex;
  flex-direction: column;
  border-style: solid;
  background-color: transparent;
  border-width: 2px;
  /* border-image: linear-gradient(220deg, #F3A12A, #3DA9F1) 1;
  border-image-slice: 10; */
  border-radius: 5px;
}
.stakingpage .accordion-item .accordion-collapse .accordion-body{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
 
  background: transparent linear-gradient(224deg, #b7b545 18%, #3DA9F1 100%) 0% 0% no-repeat padding-box;

}
.stakingpage .accordion-body{
  text-align: center;

}
.stakingpage .accordion .accordion-header p{font-size:14px !important;
 
}


/* .stakingpage .accordion-button[aria-expanded="true"]{
  color: #000 !important;
  background: transparent linear-gradient(212deg, #F3A12A 0%, #3DA9F1 100%) 0% 0% no-repeat padding-box !important;
} */
.stakingpage .accordion-button[aria-expanded="true"]  p{
  color: #000 !important;
  font-weight: 700 !important;
  
}
.stakingpage .accordion-button:focus {
  box-shadow: none !important;
  
}
.stakingpage .stakingbtnsec{
  text-align: center;
}
.stakingpage .accordion-button .headerbg .days{
  font-weight: 800;

}
.stakingpage .stakingbtnsec .btn_yellow{
  width: 150px !important;
}
.stakingpage .input-group .form-control{
  background-color: #f4f4f4;
  border-radius: 6px;
  border: 1px solid transparent;
  height: 48px;
  font-size: 13px !important;
  color: #9B9B9B;
}
.stakingpage .input-group .form-control::placeholder
{
  font-size: 13px !important;
  color: #9B9B9B;

}
.stakingpage .input-group .input-group-append span{
  background-color: #f4f4f4;
  border-radius: 6px;
  border: 1px solid transparent;
  height: 46px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  margin-top: 1px;
}

.stakingpage .input-group .input-group-append .input_btn{
  background-color: #F3A12A;
  border: none;
  border-radius: 10px;
  padding: 6px;
  width: 60px;
  font-size: 13px !important;
  border:1px solid #F3A12A;
  color:#fff;
}
.stakingpage .input-group .input-group-append .input_btn:hover
{
background-color: transparent;
color:#F3A12A;
}
.stakingpage .descinput{
  color: #F3A12A;
  font-weight: 300 !important;
  font-size: 14px !important;
}
.stakingpage .descline{
 color: #9B9B9B;
 font-size: 13px;
}
.stakingpage  .input-group .input-group-append .dropdown button{
 background-color: #F3A12A;
 border-color:#F3A12A ;
 border-radius: 10px;
 color: #fff;
 height: 33.5px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    justify-content: space-between;
 }
 .form-control:focus{
  box-shadow: none;
  color: #fff;
 }
 .white_text_md
{
  color:#fff;
  font-size: 16px !important;
  font-weight:300 !important;
}


.rewardspage  .form-control{
  background-color: #111111;
  border: none;
  height: 48px;
  color: #fff !important;
  font-size: 14px;
}
.rewardspage  .firstrow .btn_yellow {
width: 100%;
height: 100%;
}
.innerpages .badgetheme{
  width: 100%;
  height: 48px;
  background: transparent linear-gradient(268deg, #4f4926 0%, #04374b 100%) 0% 0% no-repeat padding-box;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerpages .bange_long
{
  width: 100%;
  height: 100%;
  background: #eaeaea !important;
  padding: 10px 0px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerpages .bange_long span
{
  color: var(--textclr) !important;
  word-wrap: break-word;
  white-space: break-spaces;
  line-height: 2;
  font-weight: 400;
}
.innerpages .badgetheme span{
opacity: 1;
font-weight: 400;
letter-spacing: 0.9px;
}

.rewardspage .balancerow .bal_title{	
  color: var(--textclr);	
  text-align: left;	
  letter-spacing: 0.9px;	
  font-weight: 400;	
  margin-bottom: 5px;	
  font-size: 13px ;	
}	
.rewardspage .balancerow .bal_value{	
  color: var(--textclr);	
  text-align: left;	
font-weight: 600;	
letter-spacing: 1.3px;	
margin-bottom: 5px;	
font-size: 15px;	
}	


.rewardspage .balancerow .bal_last{
  color: #ECECEC;
  text-align: left;
  letter-spacing: 0.9px;
  font-weight: 200;
  margin-bottom: 5px;
  font-size: 13px;
  
}
.rewardspage .rewardsbtnsec {
  text-align: center;

    
  }
  
.rewardspage .rewardsbtnsec .btn_yellow{
width: 150px !important;
  
}
.innerpages .table thead {
  background: #f6f6f6;
  height: 48px;

}

.innerpages .table tbody .imgwhite{
  background-color: #fff;
  border-radius: 25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

}
.innerpages .table tbody .imgwhite .imgblk {
  margin-right: 0px;
}
.innerpages .table tbody .namelist {
display: flex;
gap: 5px;
align-items: center;

}
.innerpages .table tbody tr, .innerpages .table thead tr{	
  color: #fff;	
}	
.innerpages .table tbody tr ,.innerpages .table tbody th{	
color: var(--textclr);	
font-weight: 600;	
  }	
  .innerpages .table tbody tr td span{	
    font-weight: 400;	
  }	
.innerpages .table thead th{	
    color: var(--textclr);	
    font-weight: 500;	
    border: none;	
    white-space: nowrap;	
    vertical-align: middle;	
    font-size: 14px;	
      }	
  .innerpages .tablesec{	
  overflow: auto;	
      }	
 .innerpages .table tbody td, .innerpages .table tbody th{	
  border-bottom: 1px solid #f6f6f6;	
  white-space: nowrap;	
          }
 .innerpages .table tbody {
  border-collapse: separate;
  border-spacing: 0 15px;
border: none;
 } .innerpages .table tbody td, .innerpages .table tbody th {
  padding: 0.7rem 0.5rem;
  font-size: 13px;
  letter-spacing: 1px;
  vertical-align: middle;
 }

.innerpages .paginationsec .pagination{
  justify-content: center;
  gap: 5px;
} 
.innerpages.rewardspage .secondcard .headsec .headtitle {

  font-size: 22px;
}
.innerpages .paginationsec .pagination .page-item .page-link {

  color: #000000;
  background-color:#4B4B4B;
  border-color: #4B4B4B;
  border-radius: 6px;
  min-width: 30px;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}


.innerpages .paginationsec .pagination .page-item.active .page-link {

  color: #000000;
  background-color: #F3A12A;
  border-color: #F3A12A;
  border-radius: 6px;
  min-width: 30px;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.innerpages .paginationsec .pagination .page-item:hover .page-link {

  color: #000000;
  background-color:#F3A12A;
  border-color: #F3A12A;
  border-radius: 6px;
}
.innerpages .paginationsec .pagination .page-item:last-child  .page-link ,.innerpages .paginationsec .pagination .page-item:first-child  .page-link{

  color: #000000;
  background-color:#F3A12A;
  border-color: #F3A12A;
  border-radius: 6px;
}
.innerpages .paginationsec .pagination .page-item.disabled .page-link {	
  color: #ababab;	
  background-color:#F3A12A7d;	
  border-color: #F3A12A7d;	
  border-radius: 6px;	
}	
.innerpages .tablesec .table tbody td .valueplan{	
  color: var(--textclr);	
}


.innerpages .table thead tr th:first-child{

border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
    }
    
.innerpages .table thead tr th:last-child{

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
      }
      .calculatepage .midsec .mid_title{	
        letter-spacing: 1px;	
      color: var(--textclr);	
      font-size: 14px;	
      font-weight: 500;	
      }	
      .calculatepage .midsec .mid_value{	
        letter-spacing: 2.25px;	
        color: var(--textclr);	
        font-size: 22px;	
        font-weight: 500;	
      }
.calculatepage .card.shadowstyle ,.calculatepage .card-body{
  padding-top: 0px;
  padding-bottom: 0px;
}
.calculatepage .lastrightsec{
  padding-top: 24px;
  padding-bottom: 24px;
}
.calculatepage .leftsec{
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 50px;
}
.calculatepage .midsec{	
  display: flex;	
  flex-direction: column;	
 	
  gap: 23px;	
  padding: 55px;	
  padding-top: 24px;	
padding-left: 5%;	
border-left: 1px solid #e2e2e2;	
border-right: 1px solid  #e2e2e2;	
}	
.calculatepage .lastrightsec .title{	
  color: var(--textclr);	
  letter-spacing: 1.75px;	
  text-align: center;	
  font-size: 25px;	
  font-weight: 500;	
}	
.calculatepage .lastrightsec .desc{	
  letter-spacing: 0.9px;	
  color: #3B3939;	
  font-size: 14px;	
}	
.calculatepage .lastrightsec .listdesc{	
  padding-left: 30px;	
  padding-right: 30px;	
}	
.calculatepage .lastrightsec .list_desc{	
  color: #373636;	
  letter-spacing: 0.9px;	
  font-size: 12px;	
}
.calculatepage .leftsec .val_cal{
  font-weight: 500;
  letter-spacing: 1.3px;
  font-size: 16px;
}
.calculatepage .leftsec .text_sliver{

  letter-spacing: 1px;
}
.calculatepage .leftsec .btn_yellow,
.sec_2 .btn_yellow,
.sec_3 .btn_yellow3,.btn_yellow{
width: 150px;
}
.sec_3 a.btn_yellow3.nav-link{
  display: inline-block !important;
}

.calculatepage .lastrightsec .listdesc .listlines{
position: relative;
}
.calculatepage .lastrightsec .listdesc .yellowcircle::before {	
  content: "";	
  position: absolute;	
  width: 10px;	
  height: 10px;	
  background: #f3a12a  0% 0% no-repeat padding-box;	
  box-shadow: 0px 0px 3px #f3a12a ;	
  border-radius: 50%;	
  display: inline-block;	
  margin-top: 5px;	
  left: -30px;	
}	
.calculatepage .lastrightsec .listdesc .bluecircle::before {	
  content: "";	
  position: absolute;	
  width: 10px;	
  height: 10px;	
  background: #3da9f1 0% 0% no-repeat padding-box;	
  box-shadow: 0px 0px 3px #3da9f1;	
  border-radius: 50%;	
  display: inline-block;	
  margin-top: 5px;	
  left:  -30px;	
}	
.calculatepage .lastrightsec .listdesc .lightcircle::before {	
  content: "";	
  position: absolute;	
  width: 10px;	
  height: 10px;	
  background: #6cdc64 0% 0% no-repeat padding-box;	
  box-shadow: 0px 0px 3px #6cdc64;	
  border-radius: 50%;	
  display: inline-block;	
  margin-top: 5px;	
  left: -30px;	
}	
  

.calculatepage .rightsection{
height: 100%;
}
.claimmodal .title{
  font-size: 16px;
  color: #fff;
  letter-spacing: 1.8px;
}
.claimmodal .claimlists{
 display: flex;
 justify-content: space-between ;

}
.claimmodal .claimlists p{
  color: #fff;
  font-size: 13px;
  font-weight: 200;
   }
   .claimmodal .claimmodal_btnsec {
text-align: center;
     }
.claimmodal .claimmodal_btnsec .btn_red,
.claimmodal .claimmodal_btnsec .btn_yellow {
  min-width: 100px;
 }
@media only screen and (max-width:575px) {
  .rewardsbtnsec .btn_yellow 
  {
    margin-left: auto !important;
    margin-right: auto !important;
  display: block !important;
    margin-bottom: 10px !important;
  }
  .rewardspage .balancerow .bal_title,.rewardspage .balancerow .bal_value,.rewardspage .balancerow .bal_last {
    text-align: center;
  }
  .lockedstaking.innerpages .headsec .headtitle {
    font-size: 18px;
}

}
@media only screen and (max-width:767px) {
 
  .calculatepage .leftsec {
    padding-right: 0px;
}
.calculatepage .leftsec .btn_yellow {
margin: auto;
}
.calculatepage .midsec{
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.calculatepage .lastrightsec .title {
  font-size: 24px;
}
.calculatepage .lastrightsec .listdesc {

  padding-right: 0px;
}
}

@media only screen and (max-width:767px) {
  .innerpages .badgetheme span {
    white-space: break-spaces;
}

}
@media only screen and (min-width:768px) and (max-width:1199px) {
  .calculatepage .leftsec .btn_yellow {
    margin: auto;
  }
}
@media only screen and (min-width:1200px) and (max-width:1350px) {
  
  .sec_last .rectanglepuzzle1,.rectanglepuzzle2 {
    top: 25% !important;
}

  .calculatepage .midsec {
    padding-left: 10%;
}
}


@media only screen and (min-width:1200px)
{
.sec_last .rectanglepuzzle1,.rectanglepuzzle2 {
  top: 20% !important;
}
}
.close-btn:hover {
  border: 1px solid #F3A12A;
  color: #000;
  background-color: #F3A12A !important;}


  .walletmodal .wallet_connect_div  img{
    width: 35px;
  }
  .walletmodal .big_image_wallet {
    max-width: 120px;
}
@media only screen and (max-width:767px) {
.walletmodal .big_image_wallet {
  max-width: 80px;
}}
@media only screen and (min-width:768px) and (max-width:1199px) {
  .landing_bg {
    min-height: unset;
}
}
@media only screen  and (max-width:991px) {
  #header .btn_yellow{
    margin-top: 10px;
  }
}

/* end manoj css */

.btn_yellow_a
{
  text-decoration: none !important;
  display: inline-block !important;
}
button:disabled	
{	
  opacity: 0.5 !important;	
  cursor: not-allowed;	
}	
.showloadercard	
{	
  position: absolute;	
  width: 100%;	
  left:0px;	
  top:0px;	
  background: #fff;	
    width: 100%;	
    z-index: 99;	
    height: 100%;	
    border-radius: 25px;	
}

.loader_card
{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  
}


.loader_card img.spin
{
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.selct_neew_style,.selct_neew_style:focus,.selct_neew_style:hover	
{	
  background-color: #fff;	
  border:1px solid #F3A12A;	
  color:var(--textclr) !important;	
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;	
}
.flex_bet_text
{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width:575px)
{
  .flex_bet_text,.flex_bet_text span
  {
    display: block !important;
  }
}

.content {
  
  display: inline;
}

.loading.blk_lkevl_stripe
{
  display: block !important;
}

.stripe {
  min-height: 10px;
  background-color: #babbbc;
  display: inline-block;
}

.small-stripe {
  width: 40%;
}

.medium-stripe {
  width: 70%;
}

.long-stripe {
  width: 100%;
}
.loading 
{
  min-width: 100px;
  display: inline;
}
 .loading .stripe {
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

@keyframes hintloading
{
  0% {
    opacity: 0.5;
  }
  50%  {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes hintloading
{
  0% {
    opacity: 0.5;
  }
  50%  {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.flex_drop_div
{
  display: flex;
  align-items: center;
}
.flex_drop_div p
{
  color: #3B3939;
    font-size: 13px;
    padding-right: 10px;
    font-weight: 500;
    margin-bottom: 15px;
}

.flex_drop_div .dropdwn_trna button
{
 margin-bottom: 15px !important; 
}


@keyframes floating {
  0% {
      transform: translate(0, 0px);
  }

  50% {
      transform: translate(0, 20px);
  }

  100% {
      transform: translate(0, -0px);
  }
}

.homeindex{
  z-index: 5;
  position: relative;
}
.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.55);
  border-color: transparent !important;
}

.blue_a_new
{
  color:#3DA9F1;
  font-weight: 500;
}
.blue_a_new:hover
{
  color:#000 !important;
}

.text_bold_blky
{
  font-weight: 500 !important;
}